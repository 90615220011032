import { useParams } from "react-router";
import useFetch from "../useFetch";
import ChatLog from "./ChatLog";

const ChatLoader = () => {

    const { id } = useParams()

    const { REACT_APP_MAINT_CHAT_LOG } = process.env;
    const { data: chatList, isPending, error } = useFetch(REACT_APP_MAINT_CHAT_LOG + 'maintid=' + id);

    return ( 
        <div className="chat-loader">
            { error && <div>{ error }</div> }
            { isPending && <div>loading...</div> }
            { chatList && <ChatLog chatList = { chatList } />}
            { chatList && chatList.length === 0 && <div className="chat-log-container">There are no messages!</div>}
        </div>
     );
}
 
export default ChatLoader;