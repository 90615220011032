import './kpis/kpis.css';
import './maintenance/maintenance.css';
import './chat/chat.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import KpiDash from './kpis/KpiDash';
import Navbar from './common/Navbar';
import AcctSmry from './home/AcctSmry';
import OpenReqLoad from './maintenance/OpenReqLoad';
import ReqDetailLoad from './maintenance/ReqDetLoad';
import Footer from './common/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <br />
      <Switch className="core-content">
      <Route exact path="/" >
        <AcctSmry />
        <KpiDash />
        <OpenReqLoad />
      </Route> 
      <Route path="/maintenance" >
        <OpenReqLoad />
      </Route> 
      <Route path="/request/:id">
        <ReqDetailLoad />
      </Route> 
      </Switch> 
      <Footer />
    </Router>
  );
}

export default App;
