import useFetch from "../useFetch";

const OpenTasks = () => {
    const { REACT_APP_OPEN_TASKS_KPI } = process.env;
    const { data:value, error, isPending } = useFetch(REACT_APP_OPEN_TASKS_KPI);

    return (
        <div>
        { error && <div>{ error }</div> }
        { isPending && <div>Loading...</div> }
        { value && <div>{ value[0].OpenMaintenance }</div> }
    </div>
    );
}
 
export default OpenTasks;