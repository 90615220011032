import useFetch from "../useFetch";

const AcctSmry = () => {

    const { REACT_APP_USER_PATH } = process.env;

    const { data:userArray, error, isPending } = useFetch( REACT_APP_USER_PATH);

    return (
        <div className="frame">
        <div className="acctSummary">
            { isPending && <h2>Welcome!</h2>}
            { error && <h2>Error loading user { error }</h2> }
            { userArray && userArray.length ? <h2>Welcome, { userArray[0].fname } { userArray[0].lname }!</h2> : <h2>user does not exist</h2> }
         </div>
        </div>
    );
}
 
export default AcctSmry;