import { Link } from "react-router-dom";

const KpiTile = (props) => {

    const kpis = props.kpis;

    return (
        <div className='kpi-container'>
            {kpis.map((kpis) => (
                <div className="kpiTile" key={kpis.id}> 
                    <Link to={kpis.link}>
                    <h3>{ kpis.name }</h3>
                    <div>{ kpis.img }</div>
                    <h4>{ kpis.query }</h4>
                    </Link>
                </div>
            ))}
        </div>
    );
}
 
export default KpiTile;