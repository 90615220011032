import useFetch from "../useFetch";

const QuoteReq = () => {
    
    const { REACT_APP_REQUIRE_QUOTE_KPI } = process.env;
    const { data:value, error, isPending } = useFetch(REACT_APP_REQUIRE_QUOTE_KPI);
    
    return (
        <div>
        { error && <div>{ error }</div> }
        { isPending && <div>Loading...</div> }
        { value && <div>{ value[0].RequireQuote }</div> }
    </div>
    );
}
 
export default QuoteReq;