import { useState } from "react";

const ChatLog = (props) => {
  
    const chatList = props.chatList;
    const [chatStart, setChatStart] = useState(0);
    const [chatLimit, setChatLimit] = useState(5);

    const handleClickMore = () => { 
        setChatLimit( chatLimit + 5)
        setChatStart( chatStart + 5)
    };

    const handleClickLess = () => { 
        setChatLimit( chatLimit - 5)
        setChatStart( chatStart - 5)
    };
 
    return (
        <div className="chat-log-container">
            { chatStart===0 ? <button disabled onClick = { handleClickLess }>First</button> : <button onClick = { handleClickLess }>Previous</button> }
            { chatList.length>chatLimit ? <button onClick = { handleClickMore }>Next</button> : <button disabled onClick = { handleClickMore }>Last</button>}
        
        { chatList.slice(chatStart, chatLimit).map((chatList) => (
            <div className="chat-log" key={ chatList.chat_id }>
                <h4 className="chat-log-name">{ chatList.name }</h4>
                <p className="chat-log-message">{ chatList.message }</p>
                <p className="chat-log-time">{ chatList.date }</p>
            </div>
        ))}
        </div>
    );
}
 
export default ChatLog;