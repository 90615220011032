import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <footer className='myFooter'>
            <div className="footer-containers">
                <h4><Link to="/about">About Us</Link></h4>
                <h4><Link to="/rentablapp">ABL Inc</Link></h4>
                <h4><Link to="/rentablapp/property-management">Property Management</Link></h4>
            </div>
            <div className="footer-containers">
                <h4><a href="https://www.biggerpockets.com/topics/popular" target="_blank" rel="noreferrer">Bigger Pockets</a></h4>
                <h4><a href="https://www.zillow.com/" target="_blank"  rel="noreferrer" >Zillow</a></h4>
                <h4 rel="noopener">Howard Hanna</h4>
            </div>
        </footer>
    );
}
 
export default Footer;