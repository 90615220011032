import { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";

const useFetch = (url) => {
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [cookies, setCookies] = useCookies();
    const id = cookies.user;
    const loggedIn = cookies.loggedIn;

    useEffect(() => {
      const abortCont = new AbortController();
      if(loggedIn)
      {
        let apicall = url + '&uid=' + id;
        fetch(apicall, { signal: abortCont.signal, credentials: 'include' })
        .then(res => {
          if( !res.ok) {
            throw Error('Could not fetch the data for that resource');
          }
          return res.json()
        })
        .then((data) => {
          setData(data);
          setIsPending(false);
          setError(null);
        })
        .catch( err => {
          if(err.name === 'AbortError'){
            console.log('fetch aborted');
          }else {
          setIsPending(false);
          setError(err.message);
          }
        })
      }
      else {
        console.log('user is not logged in');
      }
    }, [id]);

      return { data, isPending, error }
}



export default useFetch;
